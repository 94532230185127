import React from "react";
import Card from "react-bootstrap/Card";
import FormGroup from "react-bootstrap/FormGroup";
import FormCheck from "react-bootstrap/FormCheck";
import Accordion from "react-bootstrap/Accordion";
import { useAccordionButton } from "react-bootstrap/AccordionButton";
import { Course } from "~/model";
import formatPrice from "~/utils/formatPrice";

interface CourseCardProps {
  readonly position: "top" | "middle" | "bottom";
  readonly course: Pick<Course, "id" | "name" | "price" | "description">;
  readonly onChangeChecked: (checked: boolean) => void;
  readonly selected: boolean;
  readonly highlighted: boolean;
}

function CourseCard({
  course,
  highlighted,
  selected,
  onChangeChecked,
  position,
}: CourseCardProps) {
  const eventKey = course.id;
  const onToggleClick = useAccordionButton(eventKey);

  return (
    <Card className={position} data-testid={`${course.id}-card`}>
      <Card.Header>
        <FormGroup
          controlId={`course${course.id}Field`}
          className="course-name"
        >
          <FormCheck
            type="checkbox"
            label={course.name}
            checked={selected}
            onChange={(e) => onChangeChecked(e.target.checked)}
          />
        </FormGroup>
        <div className="key">
          {highlighted && (
            <div
              role="img"
              aria-label="highlighted"
              className="key-circle green"
            />
          )}
          {formatPrice(course.price)}
        </div>
        <button
          type="button"
          className="accordion-caret"
          onClick={onToggleClick}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 16 16"
            fill="#FFF"
          >
            <path
              fillRule="evenodd"
              d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"
            />
          </svg>
        </button>
      </Card.Header>
      <Accordion.Collapse eventKey={eventKey}>
        <Card.Body
          dangerouslySetInnerHTML={{ __html: course.description }}
        ></Card.Body>
      </Accordion.Collapse>
    </Card>
  );
}

export default CourseCard;
