function findOrThrow<T>(
  items: ReadonlyArray<T>,
  predicate: (item: T) => boolean,
  message: string
): T {
  const index = items.findIndex(predicate);
  if (index === -1) {
    throw new Error(message);
  }
  return items[index];
}

export { findOrThrow };
