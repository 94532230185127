import React, { FormEvent, useMemo, useState } from "react";
import { graphql } from "gatsby";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/Container";
import Alert from "react-bootstrap/Alert";
import Select from "react-select";
import reactSelectStyles from "~/components/reactSelectStyles";
import uniq from "lodash/uniq";
import difference from "lodash/difference";
import isEqual from "lodash/isEqual";
import sortBy from "lodash/sortBy";
import Layout from "~/components/layout";
import Seo from "~/components/seo";
import ProgramRow from "~/components/programRow";
import { PageProps } from "~/gatsbyPages";
import {
  Pathway,
  Program,
  Course,
  Experience,
  RelayConnection,
  sumPrices,
  relayConnectionToNodes,
  getFilterCourseIds,
} from "~/model";
import ellipse from "~/images/ellipse.svg";
import formatPrice from "~/utils/formatPrice";
import { findOrThrow } from "~/utils/array";

type PathwaysPageProps = PageProps<{
  readonly allOpenEdxContentPathway: RelayConnection<Pathway>;
  readonly allOpenEdxContentExperience: RelayConnection<Experience>;
  readonly allOpenEdxContentCourse: RelayConnection<
    Pick<
      Course,
      | "id"
      | "name"
      | "programId"
      | "price"
      | "description"
      | "openEdxId"
      | "ordering"
    >
  >;
  readonly allOpenEdxContentProgram: RelayConnection<
    Pick<Program, "id" | "name">
  >;
}>;

interface SubmittedFilter {
  readonly pathway: Pathway;
  readonly experiences: ReadonlyArray<Experience>;
}

function Pathways({
  data: {
    allOpenEdxContentPathway,
    allOpenEdxContentExperience,
    allOpenEdxContentProgram,
    allOpenEdxContentCourse,
  },
}: PathwaysPageProps) {
  const programs = relayConnectionToNodes(allOpenEdxContentProgram);
  const allCourses = relayConnectionToNodes(allOpenEdxContentCourse);

  const [checkedCourseIds, setCheckedCourseIds] = useState<
    ReadonlyArray<string>
  >(allCourses.map((c) => c.id));
  const [selectedPathwayId, setSelectedPathwayId] = useState<
    string | undefined
  >();
  const [selectedExperienceIds, setSelectedExperienceIds] = useState<
    ReadonlyArray<string>
  >([]);
  const checkedCourses = allCourses.filter((c) =>
    checkedCourseIds.includes(c.id)
  );

  const allExperiences = relayConnectionToNodes(allOpenEdxContentExperience);
  const experienceOptions = allExperiences.map((node) => ({
    value: node.id,
    label: node.name,
  }));
  const allPathways = relayConnectionToNodes(allOpenEdxContentPathway);
  const pathwayOptions = allPathways.map((node) => ({
    value: node.id,
    label: node.name,
  }));

  const [submittedFilterError, setSubmittedFilterError] = useState(false);
  const [submittedFilter, setSubmittedFilter] = useState<
    SubmittedFilter | undefined
  >(undefined);
  const onFilterSubmit = (e: FormEvent) => {
    e.preventDefault();

    if (!selectedPathwayId) {
      setSubmittedFilterError(true);
      return;
    }

    setSubmittedFilterError(false);
    const pathway = findOrThrow(
      allPathways,
      (p) => p.id === selectedPathwayId,
      `No pathway with ${selectedPathwayId}`
    );
    const experiences = allExperiences.filter((e) =>
      selectedExperienceIds.includes(e.id)
    );
    setSubmittedFilter({ pathway, experiences });
    setCheckedCourseIds(getFilterCourseIds(pathway, experiences));
  };

  const onResetFiltersClick = () => {
    setSubmittedFilter(undefined);
    setSelectedPathwayId(undefined);
    setSelectedExperienceIds([]);
  };

  const dotCourseIds = useMemo(() => {
    if (!submittedFilter) {
      return [];
    }

    return getFilterCourseIds(
      submittedFilter.pathway,
      submittedFilter.experiences
    );
  }, [submittedFilter]);

  const menuPortalTarget =
    typeof document === "undefined" ? undefined : document.body;

  const hasFilterWithChanges =
    !!submittedFilter &&
    !isEqual(sortBy(dotCourseIds), sortBy(checkedCourseIds));

  const onCheckoutClick = () => {
    const searchParams = new URLSearchParams();
    const checkedCourses = allCourses.filter((c) =>
      checkedCourseIds.includes(c.id)
    );
    checkedCourses.forEach((course) => {
      searchParams.append("courses", course.openEdxId);
    });
    window.location.assign(
      `${process.env.GATSBY_OPEN_EDX_CHECKOUT_URL}/?${searchParams.toString()}`
    );
  };

  return (
    <Layout>
      <Seo
        title="Solar career pathways and courses"
        description="Find the right courses for your solar career paths."
      />
      <Container>
        <Row className="filter-wrapper">
          <Col>
            <h1 className="sm">Create your course list</h1>
            <div className="filters">
              <Form onSubmit={onFilterSubmit}>
                <Row>
                  <Col sm={12} md={6}>
                    <Form.Group className="mb-3" controlId="careerGoalField">
                      <Form.Label>Career goal</Form.Label>
                      <Select
                        inputId="careerGoalField"
                        placeholder="Select"
                        options={pathwayOptions}
                        value={
                          selectedPathwayId === undefined
                            ? null
                            : pathwayOptions.find(
                                (o) => o.value === selectedPathwayId
                              )
                        }
                        onChange={(selected) => {
                          setSelectedPathwayId(selected?.value);
                        }}
                        isSearchable={false}
                        isClearable
                        menuPortalTarget={menuPortalTarget}
                        styles={reactSelectStyles}
                        theme={(theme) => ({
                          ...theme,
                          colors: {
                            ...theme.colors,
                            primary25: "#2584FE",
                            primary: "#2584FE",
                            neutral0: "#3F4A61",
                            neutral90: "#FFF",
                            neutral80: "#FFF",
                            neutral70: "#FFF",
                            neutral60: "#FFF",
                            neutral50: "#FFF",
                            neutral40: "#2584FE",
                            neutral30: "#FFF",
                            neutral20: "#838EA7",
                            neutral10: "#2584FE",
                            neutral5: "#FFF",
                          },
                        })}
                      />
                    </Form.Group>
                  </Col>
                  <Col sm={12} md={6}>
                    <Form.Group
                      className="mb-3"
                      controlId="currentSkillLevelField"
                    >
                      <Form.Label>Current skill level</Form.Label>
                      <Select
                        inputId="currentSkillLevelField"
                        placeholder="Select all that apply"
                        options={experienceOptions}
                        value={experienceOptions.filter((e) =>
                          selectedExperienceIds.includes(e.value)
                        )}
                        onChange={(selected) => {
                          setSelectedExperienceIds(
                            selected.map((o) => o.value)
                          );
                        }}
                        isMulti
                        menuPortalTarget={menuPortalTarget}
                        styles={reactSelectStyles}
                        theme={(theme) => ({
                          ...theme,
                          colors: {
                            ...theme.colors,
                            primary25: "#2584FE",
                            primary: "#2584FE",
                            neutral0: "#3F4A61",
                            neutral90: "#FFF",
                            neutral80: "#FFF",
                            neutral70: "#FFF",
                            neutral60: "#FFF",
                            neutral50: "#FFF",
                            neutral40: "#2584FE",
                            neutral30: "#FFF",
                            neutral20: "#838EA7",
                            neutral10: "#2584FE",
                            neutral5: "#FFF",
                          },
                        })}
                      />
                    </Form.Group>
                  </Col>
                </Row>

                <Row className="mt-3">
                  <Col>
                    <Button type="submit" variant="primary" size="sm">
                      Filter Courses
                    </Button>
                    <Button
                      type="button"
                      variant="link"
                      size="sm"
                      onClick={onResetFiltersClick}
                    >
                      Reset Filters
                    </Button>
                  </Col>
                </Row>
                <Alert
                  variant="danger"
                  show={submittedFilterError}
                  className="mt-3"
                  aria-label="Career goal is a required field, please select a career goal."
                >
                  Career goal is a required field, please select a career goal.
                </Alert>
              </Form>

              <div className="ellipse">
                <img
                  src={ellipse}
                  alt="circle for decoration"
                  aria-label="hidden"
                />
              </div>
            </div>
          </Col>
        </Row>

        <Row>
          <Col className="d-flex justify-content-end">
            <div className="key-reference">
              <div className="key-circle green"></div>
              <span>Training Path</span>
            </div>
          </Col>
        </Row>

        <section className="courses-section">
          {programs.map((program) => {
            const courses = sortBy(
              allCourses.filter((c) => c.programId === program.id),
              ["ordering"]
            );
            return (
              <ProgramRow
                key={program.id}
                program={program}
                courses={courses}
                highlightedCourseIds={dotCourseIds}
                checkedCourseIds={checkedCourseIds}
                onCheckCourseIds={(
                  courseIds: ReadonlyArray<string>,
                  checked: boolean
                ) => {
                  if (checked) {
                    setCheckedCourseIds((p) => uniq([...p, ...courseIds]));
                    return;
                  }
                  setCheckedCourseIds((p) => difference(p, courseIds));
                }}
              />
            );
          })}
        </section>

        <div className="checkout-wrapper">
          <Container>
            <Row className="d-flex align-items-center">
              <Col xs={12} sm={10}>
                {submittedFilter && (
                  <div data-testid="filter-description">
                    <span className="fw-bold">Filter:</span>{" "}
                    {submittedFilter.pathway.name}
                    {submittedFilter.experiences.length > 0 && (
                      <>
                        {" / "}
                        {submittedFilter.experiences
                          .map((e) => e.name)
                          .join(", ")}
                        {hasFilterWithChanges && " *"}
                      </>
                    )}
                  </div>
                )}
                <div data-testid="checkout-summary">
                  <span className="fw-bold">
                    {checkedCourses.length} course
                    {checkedCourses.length !== 1 && "s"} selected. Total =
                  </span>{" "}
                  <span className="price">
                    {formatPrice(sumPrices(checkedCourses.map((c) => c.price)))}
                  </span>
                </div>
                {hasFilterWithChanges && (
                  <small className="grey-text">
                    *You have added or removed courses from the filtered list
                    provided
                  </small>
                )}
              </Col>
              <Col xs={12} sm={2} className="d-flex align-items-center">
                <Button
                  type="button"
                  variant="primary"
                  size="sm"
                  onClick={onCheckoutClick}
                  disabled={checkedCourseIds.length === 0}
                >
                  Checkout
                </Button>
              </Col>
            </Row>
          </Container>
        </div>
      </Container>
    </Layout>
  );
}

const query = graphql`
  query PathwaysQuery {
    allOpenEdxContentPathway {
      edges {
        node {
          __typename
          id
          name
          courseIds
        }
      }
    }
    allOpenEdxContentExperience {
      edges {
        node {
          __typename
          id
          name
          exemptedCourseIds
        }
      }
    }
    allOpenEdxContentCourse {
      edges {
        node {
          __typename
          id
          openEdxId
          name
          programId
          price {
            aud
            php
          }
          description
        }
      }
    }
    allOpenEdxContentProgram {
      edges {
        node {
          __typename
          id
          name
        }
      }
    }
  }
`;

export { query };
export default Pathways;
