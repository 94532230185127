import React from "react";
import Accordion from "react-bootstrap/Accordion";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import CourseCard from "~/components/courseCard";
import { Course, Program } from "~/model";

interface ProgramRowProps {
  readonly program: Program;
  readonly courses: ReadonlyArray<Pick<Course, "id" | "name" | "description">>;
  readonly checkedCourseIds: ReadonlyArray<string>;
  readonly highlightedCourseIds: ReadonlyArray<string>;
  readonly onCheckCourseIds: (
    courseIds: ReadonlyArray<string>,
    checked: boolean
  ) => void;
}

function ProgramRow({
  program,
  courses,
  highlightedCourseIds,
  checkedCourseIds,
  onCheckCourseIds,
}: ProgramRowProps) {
  const courseIds = courses.map((c) => c.id);
  const allCoursesChecked = courseIds.every((cId) =>
    checkedCourseIds.includes(cId)
  );
  return (
    <Row>
      <Col className="course-category">
        <Form.Group className="mb-3" controlId={`program${program.id}Field`}>
          <Form.Check
            type="checkbox"
            className="check-lg"
            label={program.name}
            checked={allCoursesChecked}
            onChange={(e) => onCheckCourseIds(courseIds, e.target.checked)}
          />
        </Form.Group>

        <Accordion>
          {courses.map((course, i) => {
            const position = (() => {
              if (i === 0) {
                return "top";
              }
              if (i >= courses.length - 1) {
                return "bottom";
              }
              return "middle";
            })();
            return (
              <CourseCard
                key={course.id}
                position={position}
                course={course}
                highlighted={highlightedCourseIds.includes(course.id)}
                selected={checkedCourseIds.includes(course.id)}
                onChangeChecked={(newChecked) =>
                  onCheckCourseIds([course.id], newChecked)
                }
              />
            );
          })}
        </Accordion>
      </Col>
    </Row>
  );
}

export default ProgramRow;
