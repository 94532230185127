import { Price } from "~/model";

function formatPrice(price?: Price) {
  if (!price) {
    return "Free";
  }
  // return `$${price.aud}`;
  return `₱${price.php}`;
}

export default formatPrice;
