import { ImageDataLike } from "gatsby-plugin-image";
import flatMap from "lodash/flatMap";
import difference from "lodash/difference";

interface RelayConnection<T> {
  readonly edges: ReadonlyArray<{
    readonly node: T;
  }>;
}

function relayConnectionToNodes<T>(
  connection: RelayConnection<T>
): ReadonlyArray<T> {
  return connection.edges.map(({ node }) => node);
}

interface Price {
  readonly aud: string;
  readonly php: string;
}

function sumPrices(prices: ReadonlyArray<Price | undefined>): Price {
  const aud = prices.reduce((acc, price) => {
    const newAud = price ? parseFloat(price.aud) : 0;
    return newAud + acc;
  }, 0);
  const php = prices.reduce((acc, price) => {
    const newPhp = price ? parseFloat(price.php) : 0;
    return newPhp + acc;
  }, 0);
  return { aud: aud.toFixed(2), php: php.toFixed(2) };
}

interface Course {
  readonly id: string;
  readonly openEdxId: string;
  readonly name: string;
  readonly ordering: number;
  readonly programId: string;
  readonly summary: string;
  readonly description: string;
  readonly price?: Price;
  readonly image: {
    readonly childImageSharp: {
      readonly gatsbyImageData: ImageDataLike;
    };
  };
}

interface Program {
  readonly id: string;
  readonly name: string;
}

interface Pathway {
  readonly id: string;
  readonly name: string;
  readonly courseIds: ReadonlyArray<string>;
}

interface Experience {
  readonly id: string;
  readonly name: string;
  readonly exemptedCourseIds: ReadonlyArray<string>;
}

function getFilterCourseIds(
  pathway: Pathway,
  experiences: ReadonlyArray<Experience>
) {
  const allExemptedCourseIds = flatMap(experiences, (e) => e.exemptedCourseIds);
  return difference(pathway.courseIds, allExemptedCourseIds);
}

export { sumPrices, relayConnectionToNodes, getFilterCourseIds };
export type { Course, Price, Experience, Program, Pathway, RelayConnection };
